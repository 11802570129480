import {
  Typography,
  Button,
  Space,
  message,
  Skeleton,
  Popconfirm,
  Tooltip,
  Modal,
  Form,
  Input,
  Select,
  Divider,
  Statistic,
} from "antd";
import {
  CheckSquareOutlined,
  FileTextOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import styles from "./transactions.module.css";
import { useCallback, useEffect, useState } from "react";
import axios from "config/axios";
import dayjs from "dayjs";
import OrderTracking from "components/OrderTracking";
import { navigate } from "@reach/router";
import axiosConfig from "config/axios";
import requestErrorHandler from "util/requestErrorHandler";
import useModules from "hooks/useModules";
import { useLocation } from "@reach/router";
import { parse } from "fast-qs";
import CancelTransaction from "components/CancelTransaction";
import ReconcileQty from "components/ReconcileQty";
import UpdateOrderInfo from "components/UpdateOrderInfo";

const { Text } = Typography;

const { Option } = Select; 
const TransactionDetail = (props: ReachPathProps & { id?: string }) => {
  const [form] = Form.useForm();
  // const [form] = Form.useForm();

  const [showTrackingModal, setShowTrackingModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const [amountShowModal, setAmountShowModal] = useState(false);
  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const [amount, setAmount] = useState("0");
  const [completingOrder, setCompletingOrder] = useState<boolean>(false);
  const [balanceType, setBalanceType] = useState<
    "overDeliver" | "underDeliver"
  >("overDeliver");
  const { search } = useLocation();

  const { status } = parse(search) as {
    status: string;
  };


  // const [dspInfo, setDspInfo] = useState<{} | any>();
  const [truckDetails, setTruckDetails] = useState<TruckDetails>();

  const { allowAction } = useModules();
  const getTransactionInfo = useCallback(async () => {
    try {
      const {
        data: { transaction },
      } = await axios.get(`transaction/gettransactionbytransid/${props.id}`);
      setTransaction(transaction);
    } catch (error) {
      requestErrorHandler(error)
    } finally {
      setLoading(false);
    }
  }, [props.id]);

  const recordBalance = async () => {
    setCompletingOrder(true);
    try {
      await axiosConfig.post("builder/record-balance", {
        orderId: transaction?.id,
        amount,
        type: balanceType,
      });
      message.success("Balance Recorded");
      getTransactionInfo();
      setOrderConfirmed(true);
      setAmountShowModal(false);
    } catch (error) {
      requestErrorHandler(error);
      setOrderConfirmed(false);
    } finally {
      // getTransactionInfo();
      setCompletingOrder(false);
    }
  };


  const archiveTransaction = async () => {
    setLoading(true);
    try {
      await axiosConfig.delete(
        `transaction/archive-invoice?transactionId=${transaction?.id}`
      );
      message.success("Transaction Canceled");
      navigate(-1);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };


  const getTruckDetails = useCallback(async () => {
    try {
      const {
        data: {
          data: { truck },
        },
      }: { data: { data: { truck: TruckDetails } } } = await axios.get(
        `go-dump/get-truck?goDumpTrackingId=${transaction?.goDumpTrackingId}`
      );

      setTruckDetails(truck);
    } catch (error) {
      // requestErrorHandler(error);
    }
  }, [transaction]);

  useEffect(() => {
    if (transaction?.goDumpTrackingId) {
      getTruckDetails();
    }
  }, [transaction]);

  useEffect(() => {
    getTransactionInfo();
  }, [getTransactionInfo]);


  const hideActions = ["delivered", "canceled"].includes(status)

  if (loading || !transaction) {
    return (
      <div className={` ${styles["details-container"]}`}>
        <Skeleton.Input active />
      </div>
    );
  }

  return (
    <div className={` ${styles["details-container"]}`}>
      <Button
        icon={<ArrowLeftOutlined />}
        type="text"
        size="small"
        onClick={() => {
          navigate(-1);
        }}
        style={{
          marginBottom: 10,
        }}
      >
        Back
      </Button>
      <div className={`${styles.topbar} ${styles["details-topbar"]}`}>
        {(allowAction("transactions-write") && !hideActions) && (
          <Space style={{ overflowX: "auto" }}>
            <Button
              icon={<CheckSquareOutlined />}
              type="link"
              size="small"
              onClick={() => setShowTrackingModal(true)}
            >
              TRACK ORDER
            </Button>
            {transaction?.goDumpTrackingId && (
              <Tooltip
                title={
                  !truckDetails?.deviceObjectId
                    ? "Unable to get tracking details"
                    : ""
                }
              >
                <Button
                  icon={<CheckSquareOutlined />}
                  type="link"
                  size="small"
                  onClick={() =>
                    navigate(
                      `/admin/transactions/live-tracking/${truckDetails?.id}?deviceObjectId=${truckDetails?.deviceObjectId}&currentTrip=${transaction?.currentTrip}&numOfTrips=${transaction?.numOfTrips}`
                    )
                  }
                >
                  LIVE TRACKING
                </Button>
              </Tooltip>
            )}

            {/* <ReconcileQty transaction={transaction} /> */}
            {/* <Button icon={<CheckSquareOutlined />} type="link" size="small">
            SET DELIVERY PAYMENT
          </Button> */}
            <Button hidden icon={<FileTextOutlined />} type="link" size="small">
              PREVIEW INVOICE
            </Button>
            <Popconfirm
              onConfirm={async () => {
                try {
                  await axios.post("builder/confrimdelivery", {
                    transactionId: transaction.id,
                  });
                  message.success("Order confirmed");
                  navigate(-1);
                } catch (error) {
                  requestErrorHandler(error);
                }
              }}
              title="Are you sure this Transaction has been delivered？"
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<CheckSquareOutlined />} type="link" size="small">
                CONFIRM DELIVERY
              </Button>
            </Popconfirm>

         <CancelTransaction transaction={transaction} />

          </Space>
        )}
        {
          status === "delivered" &&   <Space>
            {allowAction("transactions-write") && (
            <>
              <Button
                onClick={() => setAmountShowModal(true)}
                icon={<CheckSquareOutlined />}
                type="link"
                size="small"
                disabled={orderConfirmed}
              >
                RECORD BALANCE
              </Button>
             { transaction?.isQuantityReconsiled ?  <Button
                // onClick={() => setAmountShowModal(true)}
                icon={<CheckSquareOutlined />}
                type="link"
                size="small"
                disabled={true}
              >
                QUANTITY RECONCILED
              </Button>: <ReconcileQty
                callback={getTransactionInfo}
                transaction={transaction}
                transactionId={transaction.id!}
              />}
            </>
          )}
          <Popconfirm
          onConfirm={async () => {
            try {
              await axios.post("transaction/complete-transaction", {
                transactionId: transaction.id,
              });
              message.success("Order Completed");
              navigate(-1);
            } catch (error) {
              requestErrorHandler(error);
            }
          }}
          title="Are you sure this Transaction has been completed?"
          okText="Yes"
          cancelText="No"
        >
          <Button icon={<CheckSquareOutlined />} type="link" size="small">
            MARK AS COMPLETED
          </Button>
        </Popconfirm>
        <Modal
            className={styles.modal}
            title="RECORD BALANCE"
            onCancel={() => setAmountShowModal(false)}
            // onOk={addMaterial}
            open={amountShowModal}
            footer={false}
          >
            <Form layout="vertical" form={form}>
              <Form.Item
                rules={[{ required: true }]}
                name="amount"
                label="Enter Balance amount"
                style={{ textTransform: "capitalize" }}
              >
                <Input
                  // defaultValue={}
                  id="amount"
                  // value={+amount > 0 ? amount : transaction.totalPrice}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                rules={[{ required: true }]}
                name="type"
                label="Select Balance Type"
                style={{ textTransform: "capitalize" }}
              >
                <Select
                  style={{ width: "100%" }}
                  // open={true}
                  defaultValue={"overDeliver"}
                  // placeholder="Select Balance Type"
                  onChange={(e: "overDeliver" | "underDeliver") =>
                    setBalanceType(e)
                  }
                >
                  <Option value="overDeliver" key="overDeliver">
                    Over Deliver
                  </Option>
                  <Option value="underDeliver" key="underDeliver">
                    Under Deliver
                  </Option>
                </Select>
              </Form.Item>
              <Divider />
              <Button
                size="large"
                type="primary"
                style={{ width: "100%" }}
                onClick={recordBalance}
                loading={completingOrder}
              >
                SUBMIT
              </Button>
            </Form>
          </Modal>
        </Space>
        } 
        <OrderTracking
          transaction={transaction}
          setShowModal={setShowTrackingModal}
          showModal={showTrackingModal}
        />
        <UpdateOrderInfo orderId={transaction.orderId} getTransactionInfo={getTransactionInfo}/>
      </div>
      <div className={styles["details-card-container"]}>
        <div className={styles["details-card"]}>
          <span>
            Invoice ID
            <b>{transaction?.frontendId}</b>
          </span>
          <span>
            Order date
            <b>{dayjs(transaction.orderPlaced ?? transaction.createdAt).format("DD MMM YYYY")}</b>
          </span>
          <span>
            Builder
            <b>{transaction.builder.businessName}</b>
          </span>
          <span>
            Payment status
            {transaction.paymentStatus ? (
              <b style={{ color: "#00962C" }}>Paid</b>
            ) : (
              <b style={{ color: "#FF612F" }}>Unpaid</b>
            )}
          </span>
          <span>
            Delivery status
            {transaction.trackingStatus === "delivered" ? (
              <b style={{ color: "#00962C" }}>Delivered</b>
            ) : (
              <b style={{ color: "#F2994A" }}>Pending</b>
            )}
          </span>
          {transaction.paymentMethod && <span>
            Payment Method

            <b >{transaction.paymentMethod}</b>

          </span>
          }
        </div>
        <div className={styles["details-card"]}>
          <span>
            <Text>Delivery option</Text>
            <b>Deliver to address</b>
          </span>
          <span>
            <Text>Delivery address</Text>
            <b>{transaction.deliveryAddress}</b>
          </span>
          {/* <span>
            <Text>State</Text>
            <b>Lagos, Nigeria</b>
          </span> */}
          <span>
            <Text>Supplier</Text>
            <b>{transaction.supplier.businessName}</b>
          </span>
          <span>
            <Text>Quantity</Text>
            <b>
              {transaction.quantity}{" "}
              {transaction.variant.material.unitOfMeasure}
            </b>
          </span>
          {transaction.deliveryPartner ? (
            <span>
              <Text>Delivery partner</Text>
              <b>Fastex delivery solution</b>
            </span>
          ) : (
            ""
          )}
          <span>
            <Text>Expected delivery date</Text>
            <b>{dayjs(transaction.deliveryDate).format("DD MM YYYY")}</b>
          </span>
        </div>
      </div>
      {transaction.narration &&
        <div className={styles["narration-card"]}>
          <b>
            Payment Narration
          </b>
          <p>
            {transaction.narration}

          </p>


        </div>
      }
      <div className={styles["main-body"]}>
        <div className={styles["profile-card"]}>
          <div className={styles.circle} />
          <div className={styles.circle} />
          <img
            src={transaction.variant.material.imageUrl}
            alt={`${transaction.variant.material.brand?.brandName} ${transaction.variant.material.category?.name} ${transaction.variant?.description}`}
            className={styles.avatar}
            height={200}
          />
          <div className={styles["profile-content"]}>
            <div className={styles.name}>
              <Typography.Title
                style={{ textTransform: "uppercase", margin: 0 }}
                level={3}
              >{`${transaction.variant.material.brand?.brandName} ${transaction.variant.material.category?.name} `}</Typography.Title>
              <Typography.Title
                style={{ textTransform: "uppercase", opacity: 0.5, margin: 0 }}
                level={4}
              >{`  ${transaction.variant.description}`}</Typography.Title>

<Divider style={{ margin: "0.5rem 0" }} />
                <span className={styles.span}>
                  <Text>Material Price</Text>
                  <b>
                    {" "}
                    <Statistic prefix="₦" value={transaction?.order?.materialPrice} />{" "}
                  </b>
                </span>
                <span className={styles.span}>
                  <Text>Delivery Price</Text>
                  <b>
                    <Statistic prefix="₦" value={transaction?.order?.deliveryPrice} />{" "}
                  </b>
                </span>
                <span className={styles.span}>
                  <Text>Total Price</Text>
                  <b>
                    <Statistic
                      prefix="₦"
                      value={+transaction?.order?.totalPrice} />{" "}
                  </b>
                </span>
            </div>
            <div className={styles.email}>
              <p></p>
            </div>
            <div className={styles.phoneNumber}>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetail;
