import { useEffect, useState } from "react";

const useTablePagination = (tableName:string) => {
    const [pagination, setPagination] = useState(() => {
        const savedPagination = sessionStorage.getItem(tableName);
        return savedPagination ? JSON.parse(savedPagination) : { current: 1, pageSize: 10 };
    });

    useEffect(() => {
        sessionStorage.setItem(tableName, JSON.stringify(pagination));
    }, [pagination]);

    return { pagination, setPagination }
}

export default useTablePagination;