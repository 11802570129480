import { Input, Table } from "antd";

import DeviceWidth from "context/MobileResizeContext";
import { SearchOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import TopTab from "components/TopTab/TopTab";
import dayjs from "dayjs";
import { navigate } from "@reach/router";
import styles from "./transactions.module.css";
import { useContext } from "react";
import useSearch from "hooks/useSearch";
import useTransactions from "hooks/useTransactions";
import useTablePagination from "hooks/useTablePagination";

const Archived = (props: ReachPathProps) => {
  const isMobile = useContext(DeviceWidth);
  const { handleSearch, isSearching, searchQuery } = useSearch();
  const {pagination, setPagination}= useTablePagination("calceled-transactions")

  const { data: transactions = [], isLoading } = useTransactions("archived") 
  // as {data:ArchivedTransaction[], isLoading:boolean}

  let searchResult = transactions?.filter((transaction) => {
    return (
      transaction?.frontendId?.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction?.builderName?.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction?.paymentMethod?.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction?.reasonArchived?.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 

      
    );
  });

  const webTableColumns = [
    {
      title: "INVOICE ID",
      dataIndex: "frontendId",
      key: "frontendId",
      // render: (text: string) => <Text>{text}</Text>,
    },
    {
      title: "DATE",
      dataIndex: "orderPlaced",
      key: "orderPlaced",
      render: (text: string, record:Transaction) =>  dayjs(text ? text : record.createdAt).format("MMM DD, YYYY") as unknown as string,
    },
    {
      title: "TYPE",
      dataIndex: "builderId",
      key: "builderId",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text ? "BUILDER" : "HOMEOWNER"}>{text ? "BUILDER" : "HOMEOWNER"}</Tooltip>
      ),
    },
    {
      title: "NAME",
      dataIndex: "builderName",
      key: "builderName",
      ellipsis: true,
     
    },
    {
      title: "REASON",
      dataIndex: "reasonArchived",
      key: "reasonArchived",
      ellipsis: true,
    },
   
  ];


  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "../", name: "Pending" },
          { to: "../pending", name: "In Progress" },
          { to: "../delivered", name: "Delivered" },
          {
            to: "../completed",
            name: "Completed",
          },
          { to: "", name: "Canceled" },
        ]}
      />
      <div className={styles.topbar}>
        <Input
          className={styles.search}
          prefix={<SearchOutlined />}
          placeholder="search"
          onChange={(e) => handleSearch(e)}
          allowClear
          size="middle"
          style={{ width: isMobile ? "100%" : "auto", marginRight: "1rem" }}
        />
      </div>
      <div className={styles.table} style={{ overflowX: "auto" }}>
        <Table
          loading={isLoading}
          dataSource={isSearching ? searchResult : transactions}
          columns={webTableColumns}
          rowClassName={styles["table-row"]}
          style={{
            minWidth: "600px",
          }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: (page, pageSize) => setPagination({ current: page, pageSize }),
          }}
          onRow={(row) => {
            return {
              onClick: (event) => {
                navigate(`history/${row.id}`, {state:row});
              },
            };
          }}
          size="middle"
        />
      </div>
    </div>
  );
};

export default Archived;
