import { Input, Table, Tooltip, Typography } from "antd";
import { useContext, useEffect, useState } from "react";

import DeviceWidth from "context/MobileResizeContext";
import { SearchOutlined } from "@ant-design/icons";
import TopTab from "components/TopTab/TopTab";
import axios from "config/axios";
import dayjs from "dayjs";
import { navigate } from "@reach/router";
import styles from "./transactions.module.css";
import useSearch from "hooks/useSearch";
import useTransactions from "hooks/useTransactions";
import useTablePagination from "hooks/useTablePagination";

const { Text } = Typography;

const Completed = (props: ReachPathProps) => {
  const isMobile = useContext(DeviceWidth);
  const { handleSearch, isSearching, searchQuery } = useSearch();
  const { data: transactions = [], isLoading } = useTransactions("completed");
  const {pagination, setPagination} = useTablePagination("completed-transactions")

  // const paidReder = (text: string | boolean) => {
  //   return (
  //     <>{text ? <Text style={{ color: "#00962C" }}>Paid</Text> : <Text style={{ color: "#FF612F" }}>Pending</Text>}</>
  //   );
  // };
  let searchResult = transactions.filter((transaction) => {
    return (
      transaction.frontendId.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction.builderName.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction.supplier.businessName.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction.trackingStatus.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      dayjs(transaction.createdAt).format("MMM DD, YYYY").indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const webTableColumns = [
    {
      title: "INVOICE ID",
      dataIndex: "frontendId",
      key: "frontendId",
    },
    {
      title: "DATE",
      dataIndex: "orderPlaced",
      key: "orderPlaced",
      render: (text: string, record:Transaction) =>  dayjs(text ? text : record.createdAt).format("MMM DD, YYYY") as unknown as string,
    },
    {
      title: "NAME",
      dataIndex: "builderName",
      key: "builderName",
      ellipsis: true,
      render: (text: string, record: any) => (
        <Tooltip title={text ? text : record.homeOwner?.firstName + " " + record.homeOwner?.lastName}>
          {text ? text : record.homeOwner?.firstName + " " + record.homeOwner?.lastName}
        </Tooltip>
      ),
    },
    {
      title: "SUPPLIER",
      dataIndex: "supplier",
      key: "supplier",
      ellipsis: true,
      render: (text: BuilderObject) => <Tooltip title={text.businessName}>{text.businessName}</Tooltip>,
    },
    {
      title: "TRACKING STATUS",
      dataIndex: "trackingStatus",
      key: "trackingStatus",
      render: (text: string) => <Text>{text}</Text>,
    },
    // {
    //   title: "TOTAL",
    //   dataIndex: "totalPrice",
    //   key: "totalPrice",
    //   ellipsis: true,
    //   render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    // },
  ];

  const mobileTableData: any = [];
  transactions.map((t: any) => {
    return mobileTableData.push({
      key: "1",
      "column-one": [t.id, "order date", t.createdAt],
      "column-two": [t.builderName, "payment ststua", t.paymentStatus],
      "column-three": [t.totalPrice, "delivery status", t.deliveryStatus],
    });
  });
  return (
    <>
      <TopTab
        links={[
          { to: "../", name: "Pending" },
          { to: "../pending", name: "In Progress" },
          { to: "../delivered", name: "Delivered" },
          { to: "", name: "Completed" },
          { to: "../history", name: "Canceled" },
        ]}
      />
      <div className={styles.container}>
        <div className={styles.topbar}>
          <Input
            className={styles.search}
            prefix={<SearchOutlined />}
            placeholder="search"
            onChange={(e) => handleSearch(e)}
            allowClear
            size="middle"
            style={{ width: isMobile ? "100%" : "auto", marginRight: "1rem" }}
          />
        </div>
        <div className={styles.table} style={{ overflowX: "auto" }}>
          
            <Table
              loading={isLoading}
              dataSource={isSearching ?searchResult: transactions}
              columns={webTableColumns}
              rowClassName={styles["table-row"]}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                onChange: (page, pageSize) => setPagination({ current: page, pageSize }),
              }}
              onRow={(row) => {
                return {
                  onClick: (event) => {
                    navigate(`order/${row.order.id}?transactionId=${row.id}`);
                  },
                };
              }}
              style={{
                minWidth: "600px",
              }}
              size="middle"
            />
          
        </div>
      </div>
    </>
  );
};

export default Completed;
