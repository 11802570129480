import { Button, DatePicker, message, Modal } from "antd";
import axiosConfig from "config/axios";
import dayjs from "dayjs";
import { useState } from "react";
import { CheckSquareOutlined, ArrowLeftOutlined } from "@ant-design/icons";

const UpdateOrderInfo = ({ orderId, getTransactionInfo }: { orderId: string, getTransactionInfo: () => void }) => {
    const [dateModalVisible, setDateModalVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | any>(null);
    const [updatingDate, setUpdatingDate] = useState(false);

    // Function to handle the date update API call
    const updateOrderDate = async () => {
        if (!selectedDate) {
            message.error("Please select a date");
            return;
        }

        setUpdatingDate(true);
        try {
            await axiosConfig.put("order/placedDate", {
                orderId: orderId,
                placedDate: selectedDate.format("YYYY-MM-DD HH:mm:ss.SSSZZ"),
            });
            message.success("Order date updated successfully!");
            setDateModalVisible(false);
            getTransactionInfo();
        } catch (error) {
            message.error("Failed to update order date");
        } finally {
            setUpdatingDate(false);
        }
    };

    return (
        <>
            <Button
                onClick={() => setDateModalVisible(true)}
                icon={<CheckSquareOutlined />}
                type="link"
                size="small"
            >
                UPDATE ORDER DATE
            </Button>

            <Modal
                title="Update Order Date"
                visible={dateModalVisible}
                onCancel={() => setDateModalVisible(false)}
                footer={[
                    <Button key="cancel" onClick={() => setDateModalVisible(false)}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={updateOrderDate}
                        loading={updatingDate}
                    >
                        Update Date
                    </Button>,
                ]}
            >
                <label>Select New Order Date:</label>
                <DatePicker
                    value={selectedDate!}
                    onChange={(date) => setSelectedDate(date)}
                    style={{ width: "100%", marginTop: 10 }}
                />
            </Modal>
        </>
    )
}

export default UpdateOrderInfo