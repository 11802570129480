import axiosConfig from "config/axios";
import { useCallback, useState } from "react";
import requestErrorHandler from "util/requestErrorHandler";

const useBuilder = () => {

    const [loading, setLoading] = useState<boolean>();
    const [builders, setBuilders] = useState<BuilderObject[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);

    const getActiveBuilders = useCallback(
        ( page: number = 0, size: number = 10, noPagination=false) => {
            setLoading(true);
            const url = noPagination ? `builder/getactivebuilders`: `builder/getactivebuilders?page=${page}&size=${size}`
            axiosConfig
                .get(url)
                .then(({ data }) => {
                    setTotalPages?.(data.data.totalPages);
                    setBuilders(
                        data.data.allActiveBuilders.map((builder: any) => {
                            const tableObject = builder.builder[0];
                            const user = builder.user[0] as User;
                            tableObject.phoneNumber = user.phoneNumber;
                            tableObject.admin = `${user.firstName} ${user.lastName}`;
                            return tableObject;
                        })
                    );
                    setLoading(false);
                })
                .catch((error) => {
                    requestErrorHandler(error);
                })
                .finally(() => setLoading(false));
        }, [])

    return {
        getActiveBuilders, loading,
        builders,
        totalPages
    }

}

export default useBuilder;