import {
  Input,
  Button,
  Table,
  Tooltip,
  Space,
  Popconfirm,
} from "antd";
import useAuth from "hooks/useAuth";
import {  useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import styles from "../payments/request.module.css";
import classNames from "classnames";
import useSearch from "hooks/useSearch";
import AddAdminModal from "components/AddAdmin";
import useBuilder from "hooks/useBuilder";
import EditAdminModal from "components/EditAdmin";
import useAdmins from "hooks/useAdmins";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";


export default function Admins(props: ReachPathProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { getActiveBuilders, builders } = useBuilder()
  const [selectedAdmin, setSelectedAdmin] = useState<User>();
  const [isEditModalVisible, setEditModalVisible] = useState(false);

  const openEditModal = (admin: User) => {
    setSelectedAdmin(admin);
    setEditModalVisible(true);
  };

  const closeEditModal = () => {
    setEditModalVisible(false);
  };


  const { user } = useAuth() as { user: User };
  const organizationId = user?.organizationId;
  const { handleSearch, isSearching, searchQuery } = useSearch();
  const {getAdmins, deleteAdmin, admins, loading} = useAdmins();

  // Filtering search results
  let searchResult = admins.filter((user) => {
    return (
      user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });



  const columns = [
    {
      title: "FIRST NAME",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "LAST NAME",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "USER ROLE",
      dataIndex: "role",
      key: "role",
      render: () => "Admin",
    },
    {
      title: "Actions",
      key: "actions",
      render: (admin: User) => (
        <Space>
        <Button onClick={() => openEditModal(admin)}>
        <EditOutlined />
        </Button>
        
        <Popconfirm title="Sure you want to delete this admin?" onConfirm={()=> {
          deleteAdmin(admin.email, getAdmins)
          }} >

        <Button danger ><DeleteOutlined /></Button>
        </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getAdmins();
    getActiveBuilders(0,0, true);
  }, [getAdmins]);

  return (
    <div>
      <div className={classNames(["c-end", styles.search])}>
        <div>
          <Input
            onChange={(e) => handleSearch(e)}
            allowClear
            placeholder="Search"
            prefix={<SearchOutlined />}
          />
        </div>
        <Button onClick={() => setShowModal(true)}>Add Admin</Button>
      </div>

      <div className={styles.table} style={{ overflowX: "auto" }}>
        <Table
          size="small"
          rowClassName={() => "table-row"}
          dataSource={isSearching ? searchResult : admins}
          columns={columns}
          loading={loading}
          style={{ minWidth: "600px", textTransform: "capitalize" }}
        />
      </div>

      {/* Add Admin Modal */}
      <AddAdminModal
        builders={builders}
        visible={showModal}
        onClose={() => setShowModal(false)}
        onSuccess={getAdmins}
        organizationId={organizationId}
      />

      <EditAdminModal
        builders={builders}
        visible={isEditModalVisible}
        onClose={closeEditModal}
        adminData={selectedAdmin!}
        refreshAdmins={getAdmins}
      />
    </div>
  );
}
