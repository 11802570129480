import {
  Button,
  Skeleton,
  Space,
  message,
  Empty,
  Modal,
  Typography,
} from "antd";
import {
  CloseOutlined,
  CheckSquareOutlined,
  DollarOutlined,
  ShareAltOutlined,
  EditOutlined,
} from "@ant-design/icons";
import styles from "./transactions.module.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useRef, useState } from "react";
import axios from "config/axios";
import useAuth from "hooks/useAuth";
import InvoiceRef from "./_invoice";
import ReactToPrint from "react-to-print";
import PayNow from "components/PayNow";
import { useMatch, useNavigate, useParams } from "@reach/router";
import requestErrorHandler from "util/requestErrorHandler";
import useQuery from "hooks/useQuery";

const Invoice = (props: ReachPathProps) => {
  const { user } = useAuth();

  const qty = useQuery().get("qty");

  const builder: any = {};

  const { id } = useParams();
  const orderId = id || "";
  const navigate = useNavigate();

  const [order, setOrder] = useState<OrderCart | null>(null);
  const [loading, setLoading] = useState(true);
  const printRef = useRef<HTMLDivElement>(null);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [paymentMethod, setPaymentMethod] = useState("transfer");
  // const [isPrinting, setIsPrinting] = useState(false);
  const [bankModal, setBankModal] = useState(false);
  const [tradeModal, setTradeModal] = useState("");
  // const support = webShareSupport();

  // async function handleShare() {
  //   if (isPrinting) return;
  //   setIsPrinting(true);
  //   try {
  //     const imageUrl = await toPng(printRef.current!);
  //     // const image = new Image();
  //     // image.src = imageUrl;
  //     const doc = new jsPDF();
  //     doc.addImage(imageUrl, "PNG", 0, 0, 210, 297);
  //     const blob = doc.output("blob");

  //     if (support === "Download") {
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.style.display = "none";
  //       a.href = url;
  //       a.target = "_blank";
  //       // the filename you want
  //       a.download = "invoice.pdf";
  //       document.body.appendChild(a);
  //       a.click();
  //       a.remove();
  //       window.URL.revokeObjectURL(url);
  //     }
  //     if (support === "Share") {
  //       const file = new File([blob], "invoice.pdf");
  //       const toBeShared = {
  //         title: "Invoice",
  //         files: [file],
  //       };
  //       navigator.share(toBeShared);
  //     } else {
  //       const file = new File([blob], "invoice.pdf");
  //       const toBeShared = {
  //         title: "Invoice",
  //         files: [file],
  //       };
  //       navigator.share(toBeShared);
  //     }
  //   } catch (e) {
  // requestErrorHandler(error);
  //   }
  //   setIsPrinting(false);
  // }

  const getOrderInfo = async function () {
    try {
      const {
        data: { data },
      } = await axios.get(`order/${orderId}`);
      setOrder(data);
      setPaymentMethod(data.paymentMethod);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const gettransactionbyid = async () => {
    try {
      const {
        data: { transaction },
      } = await axios.get(`transaction/gettransactionbyid/${orderId}`);
      setTransactions(transaction);
    } catch (error) {
      requestErrorHandler(error);
    }
  };

  // const showOrderNumber =
  //   order?.paymentStatus !== "failure" && order?.paymentStatus !== "notPaid" ? (
  //     order?.orderNumber
  //   ) : (
  //     <Tooltip title="Complete payment to see Order number">
  //       "********"{" "}
  //     </Tooltip>
  //   );

  useEffect(() => {
    Promise.all([getOrderInfo(), gettransactionbyid()]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className={` ${styles["details-container"]}`}>
        <Skeleton.Input active />
      </div>
    );
  }

  if (!order)
    return (
      <Empty>
        An error occurred, <br /> please try again later
      </Empty>
    );

  return (
    <div className={styles["details-container"]}>
      <Button
        icon={<ArrowLeftOutlined />}
        type="text"
        size="small"
        onClick={() => {
          navigate(-1);
        }}
        style={{
          marginBottom: 10,
        }}
      >
        Back
      </Button>
      <div className={`${styles.topbar} ${styles["details-topbar"]}`}>
        <div style={{ opacity: 0 }}>
          <Button
            onClick={() => navigate("/builders/transactions")}
            type="text"
            style={{ padding: 0 }}
          >
            Order
          </Button>
          {/* <span style={{ opacity: ".5" }}> | {showOrderNumber}</span> */}
        </div>
        {!order.isPaidFor && order.isApproved === "pending" && (
          <EditButton orderId={orderId!} />
        )}
      </div>
      <InvoiceRef
        qty={qty!}
        order={order}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        orderItems={order.order_items}
        ref={printRef}
      />

      <PayNow
        bankModal={bankModal}
        builder={builder!}
        getOrderInfo={getOrderInfo}
        order={order}
        setBankModal={setBankModal}
      />
      <div className={styles.invoice__footer}>
        <Space style={{ flexWrap: "wrap" }}>
          <ReactToPrint
            bodyClass="p-1"
            trigger={() => {
              return (
                <Button icon={<ShareAltOutlined />} type="primary">
                  Download {order.isPaidFor ? "Receipt" : "Invoice"}
                </Button>
              );
            }}
            content={() => printRef.current}
          />
          {order.isApproved === "pending" && (
            <Button
              icon={<CheckSquareOutlined />}
              type="link"
              size="small"
              onClick={async () => {
                try {
                  await axios.post(`order/approveorder`, {
                    orderId: order.id,
                    userId: user!.id,
                  });
                  message.success("Order approved");
                } catch (error) {
                  requestErrorHandler(error);
                } finally {
                  getOrderInfo();
                }
              }}
            >
              Approve order
            </Button>
          )}
          {order.isApproved !== "rejected" && !order.isPaidFor && (
            <Button
              icon={<DollarOutlined />}
              onClick={async () => {
                try {
                  if (paymentMethod === "credit") {
                    if (!builder!.builderCreditId) {
                      return setTradeModal(
                        "You can't pay with trade credit at this time"
                      );
                    }
                    if (
                      order.totalPrice * 1.07 >
                      (builder?.availableCredit || 0)
                    ) {
                      return setTradeModal(
                        "You don't have enough trade credit to pay for this order"
                      );
                    }
                    await axios.post("payment/paywithcredit", {
                      orderId: orderId,
                    });
                    transactions.forEach((transaction) => {
                      axios.post("paymentpartner/createpayment", {
                        transactionId: transaction.id,
                      });
                    });
                    getOrderInfo();
                  } else {
                    setBankModal(true);
                  }
                } catch (error) {
                  requestErrorHandler(error);
                }
              }}
              type="link"
              size="small"
              hidden={order.paymentStatus !== "notPaid"}
            >
              Pay Now
            </Button>
          )}
          {order.isApproved === "pending" && (
            <Fragment>
              <Button
                icon={<CloseOutlined />}
                type="primary"
                danger
                // size="small"
                onClick={async () => {
                  try {
                    await axios.post(`order/cancelorder`, {
                      orderId: order.id,
                      userId: user!.id,
                    });
                    message.success("Order Canceled");
                  } catch (error) {
                    requestErrorHandler(error);
                  } finally {
                    getOrderInfo();
                  }
                }}
              >
                Cancel order
              </Button>
            </Fragment>
          )}
        </Space>
      </div>
      <Modal
        title="Oh no!"
        open={!!tradeModal}
        onCancel={() => setTradeModal("")}
      >
        <Typography.Title level={3}>{tradeModal}</Typography.Title>
        <Typography.Title level={5}>You can stil pay to:</Typography.Title>
        <p>Bank name: {builder!.accountBank}</p>
        <p>Account name: {builder!.accountName}</p>
        <Typography.Paragraph>
          Account number:{" "}
          <Typography.Text copyable>{builder!.accountNumber}</Typography.Text>
        </Typography.Paragraph>
        <p>
          <small>
            Your order will be automatically processed once payment is
            confirmed.
          </small>
        </p>
      </Modal>
    </div>
  );
};

export default Invoice;

function EditButton({ orderId }: { orderId: string }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <Button
      loading={isLoading}
      icon={<EditOutlined />}
      type="primary"
      style={{ display: "flex", placeItems: "center" }}
      onClick={async () => {
        if (isLoading) return;
        setIsLoading(true);
        try {
          await axios.post("order/return", { orderId });
          navigate("/builders/buy");
        } catch (error) {
          requestErrorHandler(error);
        }
        setIsLoading(false);
      }}
    >
      Edit Order
    </Button>
  );
}
