import { Modal, Input, Select, Button, message, Form, Row, Col, Checkbox } from "antd";
import { useEffect, useState } from "react";
import axios from "config/axios";
import useAdmins from "hooks/useAdmins";

const { Option } = Select;

interface EditAdminModalProps {
    visible: boolean;
    onClose: () => void;
    adminData: User;
    refreshAdmins: () => void;
    builders:BuilderObject[]
}

export default function EditAdminModal({
    visible,
    onClose,
    adminData,
    refreshAdmins,
    builders
}: EditAdminModalProps) {
    const [form] = Form.useForm();

    const {editAdmin} = useAdmins();

    useEffect(() => {
        if (adminData) {
            form.setFieldsValue(adminData);
        }
    }, [adminData, form]);

     const [selectedValues, setSelectedValues] = useState<string[]>([]);
      const [isAllSelected, setIsAllSelected] = useState(false);
    
      const handleChange = (values: string[]) => {
        if (values.includes("all")) {
          if (selectedValues.length === builders.length) {
            form.setFieldsValue({ selectedBuilders: [] });
            setSelectedValues([]);
            setIsAllSelected(false);
          } else {
            const allBuilderIds = builders.map((builder) => builder.id);
            form.setFieldsValue({ selectedBuilders: allBuilderIds });
            setSelectedValues(allBuilderIds);
            setIsAllSelected(true);
          }
        } else if (values.includes("cancel")) {
          form.setFieldsValue({ selectedBuilders: [] });
          setSelectedValues([]);
          setIsAllSelected(false);
        } else {
          form.setFieldsValue({ selectedBuilders: values });
          setSelectedValues(values);
          setIsAllSelected(values.length === builders.length);
        }
      };


    return (
        <Modal
            title="Edit Admin"
            open={visible}
            onCancel={onClose}
            footer={null}
        >
            <Form form={form} layout="vertical" onFinish={(values) => editAdmin(values, refreshAdmins, onClose)}>
                <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[{ required: true, message: "Please enter first name" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[{ required: true, message: "Please enter last name" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        { required: true, message: "Please enter email" },
                        { type: "email", message: "Please enter a valid email" },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Phone Number"
                    name="phoneNumber"
                    rules={[{ required: true, message: "Please enter phone number" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Selected Builders"
                    name="selectedBuilders"
                    rules={[{ required: true, message: "Please select at least one builder" }]}
                >
                    <Select mode="multiple" value={selectedValues} onChange={handleChange} allowClear>
                        {!isAllSelected ? <Option style={{ color: "#00962c" }} key="all" value="all">Select All</Option> : <Option key="cancel" value="cancel">Cancel</Option>}
                        {builders.map((builder) => (
                            <Option key={builder.id} value={builder.id}>{builder.businessName}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="selectedModules" label="Select Modules" rules={[{ required: true, message: "Select at least one Module" }]}>
                    <Checkbox.Group className="w-100">
                        <Row>
                            <Col xs={12}>
                                <Checkbox value="dashboard">Dashboard</Checkbox>
                            </Col>
                            <Col xs={12}>
                                <Checkbox value="dashboard-write">Write Access</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Checkbox value="transactions">Transaction</Checkbox>
                            </Col>
                            <Col xs={12}>
                                <Checkbox value="transactions-write">Write Access</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Checkbox value="ho-transactions">HO Transaction</Checkbox>
                            </Col>
                            <Col xs={12}>
                                <Checkbox value="ho-transactions-write">
                                    Write Access
                                </Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Checkbox value="homeowners">Homeowner</Checkbox>
                            </Col>
                            <Col xs={12}>
                                <Checkbox value="homeowners-write">Write Access</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Checkbox value="brands">Brands</Checkbox>
                            </Col>
                            <Col xs={12}>
                                <Checkbox value="brands-write">Write Access</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Checkbox value="materials">Materials</Checkbox>
                            </Col>
                            <Col xs={12}>
                                <Checkbox value="materials-write">Write Access</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Checkbox value="builders">Builders</Checkbox>
                            </Col>
                            <Col xs={12}>
                                <Checkbox value="builders-write">Write Access</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Checkbox value="suppliers">Suppliers</Checkbox>
                            </Col>
                            <Col xs={12}>
                                <Checkbox value="suppliers-write">Write Access</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Checkbox value="dsp">DSP</Checkbox>
                            </Col>
                            <Col xs={12}>
                                <Checkbox value="dsp-write">Write Access</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Checkbox value="payments">Payment</Checkbox>
                            </Col>
                            <Col xs={12}>
                                <Checkbox value="payments-write">Write Access</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Checkbox value="user-management">User Management</Checkbox>
                            </Col>
                            <Col xs={12}>
                                <Checkbox value="user-management-write">
                                    Write Access
                                </Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Checkbox value="settings">Settings</Checkbox>
                            </Col>
                            <Col xs={12}>
                                <Checkbox value="settings-write">Write Access</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Checkbox value="profile">Profile</Checkbox>
                            </Col>
                            <Col xs={12}>
                                <Checkbox value="profile-write">Write Access</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Checkbox value="blog">Blog</Checkbox>
                            </Col>
                            <Col xs={12}>
                                <Checkbox value="blog-write">Write Access</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Checkbox value="pending-signups">Pending Signups</Checkbox>
                            </Col>
                            <Col xs={12}>
                                <Checkbox value="pending-signups-write">
                                    Write Access
                                </Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Checkbox value="contact-sales">Contact Sales</Checkbox>
                            </Col>
                            <Col xs={12}>
                                <Checkbox value="contact-sales-write">Write Access</Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Update Admin
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}
