import { Input, Table } from "antd";
import { Tooltip, message } from "antd";

import DeviceWidth from "context/MobileResizeContext";
import { SearchOutlined } from "@ant-design/icons";
import TopTab from "components/TopTab/TopTab";
import dayjs from "dayjs";
import { navigate } from "@reach/router";
import styles from "./transactions.module.css";
import { useContext } from "react";
import useSearch from "hooks/useSearch";
import useTransactions from "hooks/useTransactions";
import useTablePagination from "hooks/useTablePagination";

const Unpaid = (props: ReachPathProps) => {
  const isMobile = useContext(DeviceWidth);
  const { handleSearch, isSearching, searchQuery } = useSearch();

  const {pagination,setPagination}= useTablePagination("unpaid-transactions")

  const { data: transactions = [], isLoading } = useTransactions("pending");

  let searchResult = transactions?.filter((transaction) => {
    return (
      transaction?.invoiceNumber?.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction.builder.businessName.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction.paymentMethod.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      dayjs(transaction.createdAt).format("MMM DD, YYYY").indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const webTableColumns = [
    {
      title: "INVOICE ID",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      // render: (text: string) => <Text>{text}</Text>,
    },
    {
      title: "DATE",
      dataIndex: "orderPlaced",
      key: "orderPlaced",
      render: (text: string, record:Transaction) =>  dayjs(text ? text : record.createdAt).format("MMM DD, YYYY") as unknown as string,
    },
    {
      title: "TYPE",
      dataIndex: "builderId",
      key: "builderId",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text ? "BUILDER" : "HOMEOWNER"}>{text ? "BUILDER" : "HOMEOWNER"}</Tooltip>
      ),
    },
    {
      title: "NAME",
      dataIndex: "builder",
      key: "builder",
      ellipsis: true,
      render: (text: any, record: any) => (
        <Tooltip
          title={
            text?.businessName ? text.businessName : record.homeOwner?.firstName + " " + record.homeOwner?.lastName
          }>
          {text?.businessName ? text.businessName : record.homeOwner?.firstName + " " + record.homeOwner?.lastName}
        </Tooltip>
      ),
    },
    {
      title: "PAYMENT METHOD",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "TOTAL PRICE",
      dataIndex: "totalPrice",
      key: "totalPrice",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>₦{text}</Tooltip>,
    },
  ];

  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "", name: "Pending" },
          { to: "pending", name: "In Progress" },
          {
            to: "delivered",
            name: "Delivered",
          },
          {
            to: "completed",
            name: "Completed",
          },
          { to: "history", name: "Canceled" },
        ]}
      />
      <div className={styles.topbar}>
        <Input
          className={styles.search}
          prefix={<SearchOutlined />}
          placeholder="search"
          onChange={(e) => handleSearch(e)}
          allowClear
          size="middle"
          style={{ width: isMobile ? "100%" : "auto", marginRight: "1rem" }}
        />
      </div>
      <div className={styles.table} style={{ overflowX: "auto" }}>
        <Table
          loading={isLoading}
          dataSource={isSearching ? searchResult : transactions}
          columns={webTableColumns}
          rowClassName={styles["table-row"]}
          style={{
            minWidth: "600px",
          }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: (page, pageSize) => setPagination({ current: page, pageSize }),
          }}
          onRow={(row) => {
            return {
              onClick: (event) => {
                navigate(`transactions/unpaid/${row.id}`);
              },
            };
          }}
          size="middle"
        />
      </div>
    </div>
  );
};

export default Unpaid;
