import { Input, Table, Tooltip, Typography } from "antd";
import { Router, navigate } from "@reach/router";
import { useContext } from "react";
import Canceled from "./canceled";
import DashboardShared from "components/DashboardShared/DashboardShared";
import DeviceWidth from "context/MobileResizeContext";
import Completeed from "./completed";
import Invoice from "./invoice/order-details";
import OrderDetails from "./OrderDetails";
import { SearchOutlined } from "@ant-design/icons";
import TopTab from "components/TopTab/TopTab";
import Tracking from "./Tracking";
import TransactionDetail from "./transaction-details";
import Unpaid from "./Unpaid";
import Delivered from "./delivered";
import UnpaidDetails from "./unpaid-details";
import dayjs from "dayjs";
import styles from "./transactions.module.css";
import useSearch from "hooks/useSearch";
import useTransactions from "hooks/useTransactions";
import ArchivedDetails from "./archived-details";
import useTablePagination from "hooks/useTablePagination";

const { Text } = Typography;
const Pending = () => {
  const isMobile = useContext(DeviceWidth);
  const { handleSearch, isSearching, searchQuery } = useSearch();
  const { data: transactions = [], isLoading } = useTransactions("active");
  const {pagination,setPagination} = useTablePagination("active-transactions")

  // const paidReder = (text: string | boolean) => {
  //   return (
  //     <>{text ? <Text style={{ color: "#00962C" }}>Paid</Text> : <Text style={{ color: "#FF612F" }}>Pending</Text>}</>
  //   );
  // };

  let searchResult = transactions.filter((transaction) => {
    return (
      transaction?.frontendId?.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction?.builderName?.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction?.supplier?.businessName?.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction?.trackingStatus?.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      dayjs(transaction?.createdAt)?.format("MMM DD, YYYY").indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const webTableColumns = [
    {
      title: "INVOICE ID",
      dataIndex: "frontendId",
      key: "frontendId",
    },
    {
      title: "DATE",
      dataIndex: "orderPlaced",
      key: "orderPlaced",
      render: (text: string, record:Transaction) =>  dayjs(text ? text : record.createdAt).format("MMM DD, YYYY") as unknown as string,
    },
    {
      title: "TYPE",
      dataIndex: "builderId",
      key: "builderId",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text ? "BUILDER" : "HOMEOWNER"}>{text ? "BUILDER" : "HOMEOWNER"}</Tooltip>
      ),
    },
    {
      title: "NAME",
      dataIndex: "builderName",
      key: "builderName",
      ellipsis: true,
      render: (text: string, record: any) => (
        <Tooltip title={text ? text : record.homeOwner?.firstName + " " + record.homeOwner?.lastName}>
          {text ? text : record.homeOwner?.firstName + " " + record.homeOwner?.lastName}
        </Tooltip>
      ),
    },
    {
      title: "SUPPLIER",
      dataIndex: "supplier",
      ellipsis: true,
      render: (text: BuilderObject) => (
        <>
          <Tooltip title={text.businessName}>{text?.businessName ?? "--"}</Tooltip>
        </>
      ),
    },
    // {
    //   title: "DSP",
    //   dataIndex: "logisticsPartner",
    //   ellipsis: true,
    //   render: (text: BuilderObject) => (
    //     <>{/* <Tooltip title={text}>{text.businessName ?? "--"}</Tooltip> */}</>
    //   ),
    // },
    {
      title: "TRACKING STATUS",
      dataIndex: "trackingStatus",
      key: "trackingStatus",
      render: (text: string) => <Text>{text.replace(/_/g, " ")}</Text>,
    },
    // {
    //   title: "TOTAL",
    //   dataIndex: "totalPrice",
    //   key: "totalPrice",
    //   ellipsis: true,
    //   render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    // },
  ];

  const mobileTableData: any = [];
  transactions.map((t: any, i) => {
    return mobileTableData.push({
      id: t.id,
      key: i,
      "column-one": [t.id, "order date", t.createdAt],
      "column-two": [t.builderName, "payment ststua", t.paymentStatus],
      "column-three": [t.totalPrice, "delivery status", t.deliveryStatus],
    });
  });

  return (
    <div className={styles.container}>
      <div className={styles.topbar}>
        <Input
          className={styles.search}
          prefix={<SearchOutlined />}
          placeholder="search"
          onChange={(e) => handleSearch(e)}
          allowClear
          size="middle"
          style={{ width: isMobile ? "100%" : "auto", marginRight: "1rem" }}
        />
      </div>
      <div className={styles.table} style={{ overflowX: "auto" }}>
          <Table
            loading={isLoading}
            dataSource={isSearching ?searchResult : transactions}
            columns={webTableColumns}
            rowClassName={styles["table-row"]}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              onChange: (page, pageSize) => setPagination({ current: page, pageSize }),
            }}
            onRow={(row) => {
              return {
                onClick: (event) => {
                  navigate(`${row.id}`);
                },
              };
            }}
            style={{
              minWidth: "600px",
            }}
            size="middle"
          />
        
        {/* {isMobile && (
          <Table
            loading={isLoading}
            dataSource={mobileTableData}
            columns={mobileTableColumns}
            rowClassName={styles["table-row"]}
            onRow={() => {
              return {
                onClick: (event) => {
                  navigate("transactions/transaction-details");
                },
              };
            }}
            size="large"
          />
        )} */}
      </div>
    </div>
  );
};

const MainView = (props: ReachPathProps) => {
  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "../", name: "Pending" },
          { to: "", name: "In Progress" },
          { to: "../delivered", name: "Delivered" },
          {
            to: "../completed",
            name: "Completed",
          },
          { to: "../history", name: "Canceled" },
        ]}
      />
      <Pending />
    </div>
  );
};

export default function Transactions(props: ReachPathProps) {
  return (
    <DashboardShared title="Transactions">
      <Router>
        <Unpaid path="/" />
        <MainView path="pending" />
        <UnpaidDetails path="unpaid/:id" />
        <TransactionDetail path=":id" />
        <OrderDetails path="order/:id" />
        <Completeed path="completed" />
        <Delivered path="delivered" />
        <Canceled path="history" />
        <ArchivedDetails path="history/:id" />
        <Tracking path="live-tracking/:truckId" />
        <Invoice path="invoice/:id" />
      </Router>
    </DashboardShared>
  );
}
