import { Form, InputNumber, Popconfirm, Table, Typography, message } from "antd";
import { useMemo, useState } from "react";

import axiosConfig from "config/axios";
import { isAxiosError } from "axios";
import useMutation from "swr/mutation";
import useSWR from "swr/immutable";

export default function LogisticsDiscountTable({ builderId }: { builderId: string }) {
  const builderDiscounts = useSWR<Discount[]>(`/go-dump/get-discount?builderId=${builderId}`, fetcher);
  const variants = useSWR<{ variants: Variant[] }>("/variant", fetcher);
  const update = useMutation(`/go-dump/get-discount?builderId=${builderId}`, updateDiscount);

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  const edit = (record: Partial<Variant> & { id: string }) => {
    form.setFieldsValue({ discount: "", ...record });
    setEditingKey(record.id);
  };

  const isEditing = (record: Variant) => record.id === editingKey;

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (variantId: string) => {
    const { discount } = await form.validateFields();

    try {
      await update.trigger({
        discount,
        builderId,
        variantId,
      });
      setEditingKey("");
      message.success("Discount updated!");
    } catch (error) {
      if (isAxiosError(error) && error.response?.data.error) {
        message.error(error.response?.data.error);
      }
      message.error("Discount not saved");
    }
  };

  const discountObject = useMemo(() => {
    return (builderDiscounts.data || []).reduce((acc, curr) => {
      acc[curr.variantId] = curr.discount;
      return acc;
    }, {} as Record<string, string>);
  }, [builderDiscounts.data]);

  const variantsDiscount = useMemo(() => {
    return variants.data?.variants.map((variant) => ({ ...variant, discount: discountObject[variant.id] || "0" }));
  }, [discountObject, variants.data?.variants]);

  return (
    <Form form={form} component={false}>
      <Table
        size="small"
        bordered
        components={{
          body: { cell: EditableCell },
        }}
        dataSource={variantsDiscount}
        loading={variants.isLoading}
        columns={[
          {
            key: "description",
            title: "Variant",
            dataIndex: "description",
            render(value) {
              return <span style={{textTransform:"capitalize"}}>{value}</span>;
            },
          },
          {
            key: "discount",
            title: "Discount",
            dataIndex: "discount",
            onCell(record) {
              return {
                record,
                dataIndex: "discount",
                title: "Discount",
                editing: isEditing(record),
              };
            },
            width: "40%",
            render(value) {
              return `₦${Number(value).toLocaleString()}`;
            },
          },
          {
            title: "Action",
            dataIndex: "operation",
            render: (_: any, record: Variant) => {
              const editable = isEditing(record);
              return editable ? (
                <span>
                  <Typography.Link
                    disabled={update.isMutating}
                    onClick={() => save(record.id)}
                    style={{ marginInlineEnd: 8 }}>
                    Save
                  </Typography.Link>
                  <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                    <a>Cancel</a>
                  </Popconfirm>
                </span>
              ) : (
                <Typography.Link disabled={editingKey !== "" || update.isMutating} onClick={() => edit(record)}>
                  Edit
                </Typography.Link>
              );
            },
          },
        ]}
      />
    </Form>
  );
}

async function fetcher<T>(url: string) {
  const { data } = await axiosConfig.get<{
    message: string;
    data: T;
  }>(url);

  return data.data;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: {
    description: string;
    discount: string;
  };
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
            {
              min: 0,
              type: "number",
              message: `${title} can't be negative`,
            },
          ]}>
          <InputNumber
            formatter={(value) => Number(value || 0).toLocaleString() || ""}
            parser={(value) => value?.replaceAll(",", "") as unknown as number}
            addonBefore="₦"
            autoFocus
          />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

async function updateDiscount(
  _key: string,
  {
    arg,
  }: {
    arg: { discount: number; variantId: string; builderId: string };
  }
) {
  return await axiosConfig.post("/go-dump/add-discount", arg);
}
