
import {
  Typography,
  Button
} from "antd";
import {
  ArrowLeftOutlined
} from "@ant-design/icons";
import styles from "./transactions.module.css";
import dayjs from "dayjs";
import { navigate } from "@reach/router";
import PageSpinner from "components/PageSpinner";
import { useCallback, useEffect, useState } from "react";
import axiosConfig from "config/axios";

const { Text } = Typography;

const ArchivedDetails = (props: ReachPathProps & { id?: string } & any) => {
  const data= props?.location?.state as ArchivedTransaction

  const [variant, setVariant ] = useState<Variant>()
  const transaction = data

  console.log({transaction})
  const getVariant = useCallback(async()=>{
    try {
      const {data:{data}} = await axiosConfig(`variant/${transaction.variantId}`) as {data:{data:{variant :Variant}}};
        console.log({data})
        setVariant(data.variant);
    }catch (error){
      
    }
  },[])
  
useEffect(()=>{
  getVariant()
},[])


// if (!transaction){
//   navigate(-1)
// }
  if (!variant){
    return <PageSpinner/>
  }

  return (<div className={` ${styles["details-container"]}`}>
    <Button
      icon={<ArrowLeftOutlined />}
      type="text"
      size="small"
      onClick={() => {
        navigate(-1);
      }}
      style={{
        marginBottom: 10,
      }}
    >
      Back
    </Button>
    <div className={styles["details-card-container"]}>
      <div className={styles["details-card"]}>
        <span>
          Invoice ID
          <b>{transaction?.frontendId}</b>
        </span>
        <span>
          Order date
          <b>{dayjs(transaction?.orderPlaced ?? transaction.createdAt  ).format("DD MMM YYYY")}</b>
        </span>
        <span>
          Builder
          <b>{transaction.builderName}</b>
        </span>
        <span>
          Payment status
          {transaction.paymentStatus ? (
            <b style={{ color: "#00962C" }}>Paid</b>
          ) : (
            <b style={{ color: "#FF612F" }}>Unpaid</b>
          )}
        </span>
        <span>
          Delivery status
          {transaction.trackingStatus === "delivered" ? (
            <b style={{ color: "#00962C" }}>Delivered</b>
          ) : (
            <b style={{ color: "#F2994A" }}>Pending</b>
          )}
        </span>
        {transaction.paymentMethod && <span>
          Payment Method

          <b >{transaction.paymentMethod}</b>

        </span>
        }
      </div>
      <div className={styles["details-card"]}>
        <span>
          <Text>Delivery option</Text>
          <b>Deliver to address</b>
        </span>
        <span>
          <Text>Delivery address</Text>
          <b>{transaction.deliveryAddress}</b>
        </span>
        <span>
          <Text>Quantity</Text>
          <b>
            {transaction.quantity}{" "}
            {variant?.material.unitOfMeasure}
          </b>
        </span>
        {transaction.deliveryPartner ? (
          <span>
            <Text>Delivery partner</Text>
            <b>Fastex delivery solution</b>
          </span>
        ) : (
          ""
        )}
        <span>
          <Text>Expected delivery date</Text>
          <b>{dayjs(transaction.deliveryDate).format("DD MM YYYY")}</b>
        </span>
      </div>
    </div>
    {transaction.reasonArchived &&
      <div className={styles["narration-card"]}>
        <b>
          Reason for Cancel
        </b>
        <p>
          {transaction.reasonArchived}

        </p>


      </div>
    }
      <div className={styles["main-body"]}>
        <div className={styles["profile-card"]}>
          <div className={styles.circle} />
          <div className={styles.circle} />
          <img
            src={variant?.material.imageUrl}
            alt={`${variant?.material.brand?.brandName} ${variant?.material.category?.name} ${variant?.description}`}
            className={styles.avatar}
            height={200}
          />
          <div className={styles["profile-content"]}>
            <div className={styles.name}>
              <Typography.Title
                style={{ textTransform: "uppercase", margin: 0 }}
                level={3}
              >{`${variant?.material.category?.name} `}</Typography.Title>
              <Typography.Title
                style={{ textTransform: "uppercase", opacity: 0.5, margin: 0 }}
                level={4}
              >{`  ${variant?.description}`}</Typography.Title>
            </div>
            <div className={styles.email}>
              <p></p>
            </div>
            <div className={styles.phoneNumber}>
              <p></p>
            </div>
          </div>
        </div>
      </div>

  </div>
  );
};

export default ArchivedDetails;
