import { Space, Table, Input, Typography, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import DeviceWidth from "context/MobileResizeContext";
import { useContext, useEffect } from "react";
import styles from "./builders.module.css";
import TopTab from "components/TopTab/TopTab";
import classNames from "classnames";
import { navigate } from "@reach/router";
import useSearch from "hooks/useSearch";
import useBuilder from "hooks/useBuilder";
const { Text } = Typography;

export function ActiveBuilders(props: ReachPathProps) {
  const isMobile = useContext(DeviceWidth);
  const { handleSearch, isSearching, searchQuery } = useSearch();
  const { getActiveBuilders, loading,
    builders,
    totalPages, } = useBuilder();

  useEffect(() => {
    getActiveBuilders(0,0, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let searchResult = builders.filter((builder) => {
    return (
      builder.businessName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
      -1 ||
      builder.admin.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      builder.adminEmail.toLowerCase().indexOf(searchQuery.toLowerCase()) >
      -1 ||
      builder.businessAddress.toLowerCase().indexOf(searchQuery.toLowerCase()) >
      -1 ||
      builder.phoneNumber.toLowerCase().indexOf(searchQuery.toLowerCase()) >
      -1 ||
      builder.status.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const columns = [
    {
      title: "BUSINESS NAME",
      dataIndex: "businessName",
      key: "businessName",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },
    {
      title: "ADMIN",
      dataIndex: "admin",
      key: "admin",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "adminEmail",
      key: "adminEmail",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text}>
          <span style={{ textTransform: "lowercase" }}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "ADDRESS",
      dataIndex: "businessAddress",
      key: "businessAddress",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (text: OrganizationStatus) => (
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          {text === "pending_confirmation" && (
            <Text style={{ color: "#F2994A" }}>Not confirmed</Text>
          )}
          {text === "pending_approval" && (
            <Text style={{ color: "#F2994A" }}>Pending</Text>
          )}
          {text === "suspended" && (
            <Text style={{ color: "#F2994A" }}>Suspended</Text>
          )}
          {text === "approved" && (
            <Text style={{ color: "#00962C" }}>Approved</Text>
          )}
          {/* <Button style={{ transform: "rotateZ(90deg)" }} type="text">
            <MoreOutlined />
          </Button> */}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "..", name: "New Requests" },
          { to: "../all", name: "All Builders" },
          { to: "", name: "Active Builders" },
          { to: "../credit", name: "Builder Credit" },
        ]}
      />
      <div className={classNames([styles.topbar, "c-end"])}>
        <Input
          allowClear
          prefix={<SearchOutlined />}
          size="middle"
          onChange={(e) => handleSearch(e)}
          style={{ width: isMobile ? "100%" : "250px", paddingRight: "1rem" }}
        />
      </div>
      <div>
        <Table
          rowClassName={styles["table-row"]}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(`details/${record.organizationId}`);
              },
            };
          }}
          columns={columns}
          dataSource={isSearching ? searchResult : builders}
          className={styles.table}
          size="middle"
          pagination={{
            pageSize: 10,
            total: totalPages * 10,
            onChange: (page) => getActiveBuilders(page - 1),
          }}
          loading={loading}
          style={{
            textTransform: "capitalize",
            fontSize: "0.2rem",
            overflowX: "auto",
            minWidth: "500px",
          }}
        />
      </div>
    </div>
  );
}
