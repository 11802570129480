import { Redirect } from "@reach/router";
import DashboardShared from "components/DashboardShared/DashboardShared";
import useAuth from "hooks/useAuth";
import useModules from "hooks/useModules";

export default function Home(props: ReachPathProps) {
  const { user } = useAuth();
  const { dashboardRoute } = useModules()
  if (!user) return <Redirect from="/" to="/login" noThrow />;

  return <DashboardShared title="Admin Dashboard">
    <Redirect from="/" to={`admin/${dashboardRoute[0]?.path}` } noThrow />
  </DashboardShared>
}
// zespumirto@gufum.com