import { Router } from "@reach/router";
import useAuth from "hooks/useAuth";
import Home from "pages";
import NotFound from "pages/404";
import Admin from "pages/admin";
import LogIn from "pages/login";
import { useEffect, useState } from "react";
import { MobileResizeContext } from "context/MobileResizeContext";
import axios from "config/axios";
import FederatedAdminOnboarding from "pages/federated-admin-onboarding";
import ForgotPassword from "pages/forgot-password";
import ResetPassword from "pages/forgot-password/reset";

function App() {
  const [isValidating, setIsValidating] = useState(true);
  const { logIn, updateToken, logOut } = useAuth();
  useEffect(() => {
    const token = localStorage.getItem("token-admin-v1");
    const selectedModules = JSON.parse(
      localStorage.getItem("selectedModules")!
    );
    (async function () {
      try {
        if (token) {
          updateToken(token);
          const {
            data: { data: user },
          } = await axios.get("user");
          logIn({ ...user, selectedModules });
        }
      } catch (error) {
        logOut();
      } finally {
        setIsValidating(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isValidating) return <></>;
  return (
    <MobileResizeContext>
      <Router data-testid="router">
        <Home path="/" />
        <LogIn path="/login" />
        <ForgotPassword path="/forgot-password/reset" />
        <ResetPassword path="/forgot-password" />
        <FederatedAdminOnboarding path="/federated-admin/complete" />
        <Admin path="/admin/*" />
        <NotFound path="*" />
      </Router>
    </MobileResizeContext>
  );
}

export default App;
