import { Input, Button, Form, Modal, message, Select, Checkbox, Row, Col } from "antd";
import axios from "config/axios";
import { useMemo, useState } from "react";
import requestErrorHandler from "util/requestErrorHandler";

const { Option } = Select;

interface AddAdminModalProps {
  visible: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  builders: { id: string; businessName: string }[];
  organizationId: string;
}

export default function AddAdminModal({ visible, onClose, onSuccess, builders, organizationId }: AddAdminModalProps) {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [builderSearchQuery, setBuilderSearchQuery] = useState<string>("");

  const handleChange = (values: string[]) => {
    if (values.includes("all")) {
      if (selectedValues.length === builders.length) {
        form.setFieldsValue({ selectedBuilders: [] });
        setSelectedValues([]);
        setIsAllSelected(false);
      } else {
        const allBuilderIds = builders.map((builder) => builder.id);
        form.setFieldsValue({ selectedBuilders: allBuilderIds });
        setSelectedValues(allBuilderIds);
        setIsAllSelected(true);
      }
    } else if (values.includes("cancel")) {
      form.setFieldsValue({ selectedBuilders: [] });
      setSelectedValues([]);
      setIsAllSelected(false);
    } else {
      form.setFieldsValue({ selectedBuilders: values });
      setSelectedValues(values);
      setIsAllSelected(values.length === builders.length);
    }
  };

  const handleFinish = async (values: any) => {
    setIsSubmitting(true);
    try {
      await axios.post("admin/add-admin", {
        ...values,
        email: values?.email?.toLowerCase(),
        organizationId,
      });
      message.success("Admin added successfully");
      onSuccess?.();
      onClose();
    } catch (err) {
      requestErrorHandler(err);
    } finally {
      setIsSubmitting(false);
    }
  };



  const handleSearch = (value: string) => {
    setBuilderSearchQuery(value);
  };


  return (
    <Modal title="Add Admin" open={visible} onCancel={onClose} footer={null}>
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Form.Item name="firstName" label="First Name" rules={[{ required: true, message: "Please enter Admin's First Name" }]}>
          <Input />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: "Please enter Admin's Last Name" }]}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Admin Email Address" rules={[{ required: true, type: "email", message: "Please enter a valid email address" }]}>
          <Input />
        </Form.Item>
        <Form.Item name="phoneNumber" label="Admin Phone Number" rules={[{ required: true, message: "Please provide a valid phone number" }]}>
          <Input />
        </Form.Item>
        <Form.Item name="selectedBuilders" label="Assign Builders" rules={[{ required: true, message: "Please select Builders" }]}>

          <Select
            showSearch
            onSearch={handleSearch}
            filterOption={false} // Disable default filtering since we're handling it manually
            placeholder="Search and select builders"
            mode="multiple"
            value={selectedValues}
            onChange={handleChange}
            allowClear
          >
            {!builderSearchQuery && !isAllSelected ? <Option style={{ color: "#00962c" }} key="all" value="all">Select All</Option> : <Option key="cancel" value="cancel">Cancel</Option>}
            {builders
              .filter((builder) =>
                builder.businessName.toLowerCase().includes(builderSearchQuery.toLowerCase())
              )
              .map((builder) => (
                <Option key={builder.id} value={builder.id}>
                  {builder.businessName}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="selectedModules" label="Select Modules" rules={[{ required: true, message: "Select at least one Module" }]}>
          <Checkbox.Group className="w-100">
            <Row>
              <Col xs={12}>
                <Checkbox value="dashboard">Dashboard</Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox value="dashboard-write">Write Access</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox value="transactions">Transaction</Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox value="transactions-write">Write Access</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox value="ho-transactions">HO Transaction</Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox value="ho-transactions-write">
                  Write Access
                </Checkbox>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox value="homeowners">Homeowner</Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox value="homeowners-write">Write Access</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox value="brands">Brands</Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox value="brands-write">Write Access</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox value="materials">Materials</Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox value="materials-write">Write Access</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox value="builders">Builders</Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox value="builders-write">Write Access</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox value="suppliers">Suppliers</Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox value="suppliers-write">Write Access</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox value="dsp">DSP</Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox value="dsp-write">Write Access</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox value="payments">Payment</Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox value="payments-write">Write Access</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox value="user-management">User Management</Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox value="user-management-write">
                  Write Access
                </Checkbox>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox value="settings">Settings</Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox value="settings-write">Write Access</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox value="profile">Profile</Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox value="profile-write">Write Access</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox value="blog">Blog</Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox value="blog-write">Write Access</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox value="pending-signups">Pending Signups</Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox value="pending-signups-write">
                  Write Access
                </Checkbox>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox value="contact-sales">Contact Sales</Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox value="contact-sales-write">Write Access</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item>
          <Button loading={isSubmitting} type="primary" htmlType="submit">Add Admin</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
