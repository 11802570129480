import { useCallback, useEffect, useState } from "react";
import axiosConfig  from "config/axios";
import { message } from "antd";
import requestErrorHandler from "util/requestErrorHandler";

const API_ENDPOINTS = {
  GET_ADMINS: "admin/matproadmins",
  DELETE_ADMIN: "admin/delete-admin",
};

type EditProps = {
    firstName:string;
            lastName:string;
            email:string;
            phoneNumber:string;
            selectedModules:string;
            selectedBuilders:string;
}

const useAdmins = () => {
  const [admins, setAdmins] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);

  const getAdmins = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(API_ENDPOINTS.GET_ADMINS);
      setAdmins(response.data.matproAdmins);
    } catch (error) {
        requestErrorHandler(error)
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteAdmin = useCallback(async (email: string, callback?:()=>void) => {
    setLoading(true);
    try {
      await axiosConfig.delete(`${API_ENDPOINTS.DELETE_ADMIN}?email=${email}`);
      message.success("Admin deleted")
      callback?.()
      
    } catch (error) {
      requestErrorHandler(error)
    } finally {
      setLoading(false);
    }
  }, [getAdmins]);

  const editAdmin = useCallback (async (values: EditProps, refreshAdmins?:()=>void, onClose?:any) => {
    try {
        const payload = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phoneNumber,
            selectedModules: values.selectedModules,
            selectedBuilders: values.selectedBuilders,
        };

        await axiosConfig.put(`admin/edit-admin`, payload);
        message.success("Admin details updated successfully!");
        refreshAdmins?.(); // Refresh the admin list
        onClose?.();
    } catch (error) {
        message.error("Failed to update admin. Please try again.");
    }
},[])

  useEffect(() => {
    getAdmins();
  }, [getAdmins]);

  return { admins, loading, getAdmins, deleteAdmin, editAdmin };
};

export default useAdmins;
