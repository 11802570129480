import axios from "config/axios";
import requestErrorHandler from "util/requestErrorHandler";
import useSWR from "swr/immutable";

type Status = "active" | "pending" | "delivered" |  "completed" | "archived";

export default function useTransactions(status: Status) {
  return useSWR<Transaction[]>(["transaction/get-transactions", status], {
    async fetcher([url]) {
      const { data } = await axios.get(status === "archived" ? "transaction/all-archived" : url, {
        params: {
          transactionType: status === "archived" ? undefined : status,
        },
      });
      return data.data;
    },
    onError(err) {
      requestErrorHandler(err);
    },
  });
}
