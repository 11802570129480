import { navigate } from "@reach/router";
import { Button, Form, Input, message, Modal, Select } from "antd";
import axiosConfig from "config/axios";
import { useState } from "react";
import requestErrorHandler from "util/requestErrorHandler";
import { CheckSquareOutlined } from "@ant-design/icons";

const { Option } = Select;

const reasons = [
  "Out of Stock",
  "Delivery location not available",
  "Outdated price",
  "Order pending for too long",
  "Payment not confirmed",
  "Others",
];

const CancelTransaction = ({ transaction }: { transaction: Transaction }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [customReason, setCustomReason] = useState("");
  const [selectedReason, setSelectedReason] = useState("");

  const archiveTransaction = async (values: { reason: string; customReason?: string }) => {
    setLoading(true);
    try {
      const reasonToSend = values.reason === "Others" ? values.customReason : values.reason;
      await axiosConfig.delete(
        `transaction/archive-invoice?transactionId=${transaction?.id}&reason=${reasonToSend}`
      );
      message.success("Transaction Canceled");
      setShowModal(false);
      navigate(-1);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button onClick={() => setShowModal(true)} icon={<CheckSquareOutlined />} type="link" size="small">
        CANCEL TRANSACTION
      </Button>
      <Modal
        title="RECORD BALANCE"
        onCancel={() => setShowModal(false)}
        open={showModal}
        footer={false}
      >
        <Form layout="vertical" form={form} onFinish={archiveTransaction}>
          <Form.Item name="reason" label="Reason" rules={[{ required: true }]}> 
            <Select 
              style={{ width: "100%" }}
              onChange={(value) => setSelectedReason(value)}
            >
              {reasons.map((value) => (
                <Option value={value} key={value}>{value}</Option>
              ))}
            </Select>
          </Form.Item>

          {selectedReason === "Others" && (
            <Form.Item
              name="customReason"
              label="Specify Reason"
              rules={[{ required: true, message: "Please enter the reason" }]}
            >
              <Input onChange={(e) => setCustomReason(e.target.value)} />
            </Form.Item>
          )}

          <Button size="large" type="primary" htmlType="submit" style={{ width: "100%" }} loading={loading}>
            SUBMIT
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default CancelTransaction;
